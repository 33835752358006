import Vue from "vue"
import VueI18n from 'vue-i18n'
import store from "../store"
import viMessage from "../locale/language/vi.json"
import enMessage from "../locale/language/en.json"

Vue.use(VueI18n)

const i18nPlugin = {
    register() {
        const locale = store.getters.locale
        const messages = {
            en: enMessage,
            vi: viMessage
        }

        return new VueI18n({
            locale,
            messages,
            fallbackLocale: locale
        })
    }
}

export default i18nPlugin
