<template>
  <div class="page page-home text-center pt-lg-4">
    <div class="page-body">
      <div class="pt-5 pt-lg-5 pb-3 pt-lg-4">
        <a href="https://onmeeting.com.vn" class="logo">
          <img src="/img/logo.svg" alt="OnMeeting" class="img-logo"/>
        </a>
      </div>
      <div class="form-join">
        <ValidationObserver ref="form" tag="form" class="form" @submit.prevent="onJoinClick()">
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label>{{ $t("Số hiệu OnMeeting ID") }}</label>
              <b-input v-model="formData.meetingId" :placeholder="$t('Nhập số hiệu OnMeeting ID')"
                       :disabled="disabled || loading || loadingSchedules"
                       size="lg" @change="loadSchedules"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label>{{ $t("Your a display name") }}</label>
              <b-input v-model="formData.displayName" :placeholder="$t('Enter a display name')"
                       :disabled="disabled || loading"
                       size="lg"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group v-if="!loadingSchedules && schedules.length > 1">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label>{{ $t("Meeting schedules") }}</label>
              <b-select v-model="formData.scheduleId" :options="schedules" :disabled="disabled || loading" size="lg"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <b-button type="submit" variant="warning" block size="lg" class="font-weight-bold"
                      :disabled="loadingSchedules || disabled || loading">
              {{ $t("Join now") }}
            </b-button>
          </b-form-group>
          <div style="font-size: 15px; font-weight: 500">
            {{ $t("By clicking 'Join', you agree to our Term of Services and Privacy Statement") }}
          </div>
        </ValidationObserver>
      </div>
    </div>
    <div class="page-footer">
      {{ $t("Developed by FPT Telecom International 2022.") }} <img src="/img/logo-fpt.png" alt="FPT Telecom"
                                                                    class="logo-fpt"/>
    </div>

    <b-modal v-model="showPassCodeModal" :title="$t('Enter a meeting pass code')">
      <ValidationObserver ref="formPassCode" tag="form" class="form">
        <b-form-group class="mb-0">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-input v-model="passCode" :placeholder="$t('Enter a meeting pass code')"
                     :disabled="disabled || loading"
                     size="lg"
                     class="text-left"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
      </ValidationObserver>

      <template #modal-footer>
        <div class="text-right">
          <b-button variant="warning" class="mr-2" @click="onPassCodeClick()">
            {{$t("Accept")}}
          </b-button>
          <b-button @click="showPassCodeModal = false">
            {{$t("Cancel")}}
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-overlay no-wrap :show="loading" style="z-index: 9999">
      <b-spinner/>
    </b-overlay>
  </div>
</template>

<script>
import meetingService from "../services/meetingService";
import dialogHelper from "../helpers/dialogHelper";

export default {
  name: 'Home',
  data() {
    return {
      disabled: false,
      loading: false,
      loadingSchedules: false,
      schedules: [],
      timeoutSchedules: null,
      formData: {
        meetingId: "",
        displayName: "",
        scheduleId: ""
      },
      showPassCodeModal: false,
      passCode: ""
    }
  },
  created() {
    try {
      this.formData.displayName = window.localStorage.getItem("displayName") || ""
      const meetingId = window.localStorage.getItem("meetingId")
      if (meetingId && meetingId.length > 0) {
        this.formData.meetingId = meetingId;
      }
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    async loadSchedules() {
      this.loadingSchedules = true
      this.loading = true
      this.schedules = []
      this.formData.scheduleId = ""

      const meetingId = this.formData.meetingId || ""
      if (meetingId.length === 0) {
        this.loadingSchedules = false
        this.loading = false
        return
      }

      const response = await meetingService.initJoin(this.formData.meetingId)
      this.loadingSchedules = false
      this.loading = false
      if (!response) {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection.")
        return
      }

      if (response.error)
        return

      this.schedules = response.data.map(s => ({
        value: s.id,
        text: s.name
      }))

      if (this.schedules.length > 0) {
        this.formData.scheduleId = this.schedules[0].value
      }
    },
    async onJoinClick(resetPassCode = true, passCodeAlert = false) {
      const valid = await this.$refs.form.validate()
      if (!valid)
        return

      this.loading = true;
      this.disabled = true;

      if (resetPassCode)
        this.passCode = ""

      const response = await meetingService.join({
        id: this.formData.scheduleId,
        onMeetingId: this.formData.meetingId,
        passCode: this.passCode,
        displayName: this.formData.displayName,
        source: "web-share"
      })

      this.loading = false;
      this.disabled = false;
      if (!response) {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection.")
        return

      }

      if (response.error) {
        let message = ""
        if (response.code === 5) {
          this.showPassCodeModal = true
          this.passCode = ""
          if (passCodeAlert)
            await dialogHelper.alert(response.message)

          if (!this.showPassCodeModal)
            this.showPassCodeModal = true

          return
        } else if (response.code === 2) {
          message = response.message.substr(8)
        } else {
          message = response.message
        }

        await dialogHelper.alert(message)
        return
      }


      this.showPassCodeModal = false
      window.localStorage.setItem("displayName", this.formData.displayName)
      window.localStorage.setItem("meetingId", this.formData.meetingId)

      await this.$store.dispatch("setMeetingData", response.data);
      await this.$router.push({name: "Meeting"});
    },
    async onPassCodeClick() {
      const valid = await this.$refs.formPassCode.validate()
      if (!valid)
        return

      await this.onJoinClick(false, true)
    }
  },
  watch: {
    "formData.meetingId": function (newValue) {
      if (this.timeoutSchedules != null) {
        clearTimeout(this.timeoutSchedules);
        this.timeoutSchedules = null;
      }

      this.timeoutSchedules = setTimeout(this.loadSchedules, 500);
    }
  }
}
</script>

<style lang="scss">
.btn.btn-warning {
  background-color: #f36f21;
  border-color: #f36f21;
  color: #ffffff;
}

.form-group {
  label {
    font-weight: 500;
    font-size: 16px;
  }

  .msg-validate-error {
    display: block;
    padding-top: 3px;
    color: red;
    font-size: 13px;
  }

  .form-control.form-control-lg {
    font-size: 16px;
    text-align: center;
  }

  .custom-select.custom-select-lg {
    font-size: 16px;
    text-align: center;
  }
}

.page-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .page-footer {
    text-align: center;
    font-weight: 500;
    padding: 10px 0;

    img {
      height: 30px;
      margin-left: 10px;
    }
  }

  .page-body {
    text-align: center;
    flex: 1;

    .logo {
      .img-logo {
        width: 320px;
        max-width: 90%;
      }
    }

    .form-join {
      width: 420px;
      max-width: 100%;
      padding: 15px;
      margin: 0 auto;
    }

    .btn.btn-warning {
      border-radius: 24px;
    }
  }
}
</style>
