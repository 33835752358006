import Vue from "vue"
import config from "../config";
import store from "../store";

const zoomPlugin = {
    register() {
        Vue.prototype.ZoomMtg = window.ZoomMtg
        Vue.prototype.ZoomMtgEmbedded = window.ZoomMtgEmbedded
        document.getElementById("zmmtg-root").style.display = "none"

        const locale = config.locale.dictionary[store.getters.locale];
        window.ZoomMtg.setZoomJSLib('https://source.zoom.us/2.15.2/lib', '/av');
        window.ZoomMtg.preLoadWasm();
        window.ZoomMtg.prepareJssdk();
        window.ZoomMtg.i18n.load(locale);
        window.ZoomMtg.i18n.reload(locale);
    }
}

export default zoomPlugin
