import Vue from 'vue';
import {extend, localize, ValidationProvider, ValidationObserver} from "vee-validate";
import {required, email, min, max, confirmed} from "vee-validate/dist/rules";
import store from "../store";
import viVeeValidate from "../locale/vee-validate/vi.json";
import enVeeValidate from "../locale/vee-validate/en.json";

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

const veeValidatePlugin = {
    register() {
        const locale = store.getters.locale;

        extend("required", required);
        extend("email", email);
        extend("min", min);
        extend("max", max);
        extend("confirmed", confirmed);

        localize({
            'en': enVeeValidate,
            'vi': viVeeValidate
        })

        localize(locale)
    }
}

export default veeValidatePlugin
