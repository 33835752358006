import axios from 'axios';
import config from '../config';
import store from "../store/index";
import qs from 'qs';
import app from "../main";
import dialogHelper from "./dialogHelper";

export default {
    options: {
        contentType: 'application/json',
        showNotifyConnectFailed: true,
        fail: null,
    },
    get: async function (url, data = {}, options) {
        return await this.sendRequest(url, data, 'get', options);
    },
    delete: async function (url, data = {}, options) {
        return await this.sendRequest(url, data, 'delete', options);
    },
    post: async function (url, data = {}, options) {
        return await this.sendRequest(url, data, 'post', options);
    },
    put: async function (url, data = {}, options) {
        return await this.sendRequest(url, data, 'put', options);
    },
    patch: async function (url, data = {}, options) {
        return await this.sendRequest(url, data, 'patch', options);
    },
    sendRequest: async function (url, data = {}, method = 'get', options = {}) {
        const self = this;
        Object.keys(this.options).forEach((key) => {
            if (typeof(options[key]) === 'undefined' || options[key] == null)
                options[key] = self.options[key];
        })

        let lowerUrl = url.toLowerCase();
        if (lowerUrl.indexOf('http://') != 0 && lowerUrl.indexOf('https://') != 0)
            url = config.apiUrl + url;

        if (options.type === 'formData') {
            options.contentType = 'multipart/form-data';
            const formData = new FormData();
            if (data != null) {
                Object.keys(data).forEach((key) => formData.append(key, data[key]));
            }

            data = formData;
        }

        let params = null;
        if (['get'].includes(method)) {
            params = {...data};
            data = null;
        }

        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Access-Control-Allow-Origin': '*',
            'LanguageCode': options.contentType
        };

        headers['Content-Language'] = config.locale.dictionary[store.getters.locale] || "vi-VN";

        const response = await axios({
            method: method,
            url: url,
            data: data,
            headers: headers,
            responseType: 'json',
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        }).catch(err => {
            // if (options.showNotifyConnectFailed)
            //     notifyHelper.error('Connect to server failed. Please check your internet connection.');

            if (options.fail != null)
                options.fail();
        });

        if (response) {
            const data = response.data;
            if (data) {
                if (data.error) {
                    const code = data.code;
                    if (code === 401)
                    {
                        await dialogHelper.alert(data.message);
                        await app.$router.push({name: "SignIn"});
                        return false;
                    }

                    if (code === 403) {
                        await dialogHelper.alert(data.message);
                        await app.$router.push({name: "Home"});
                        return false;
                    }

                    if (code === 400) {
                        let message = data.message;
                        if (Array.isArray(data.data)) {
                            message = "<ul>"
                            data.data.forEach((error) => message += `<li>${error.message}</li>`)
                            message += "</ul>"
                        }

                        return false;
                    }
                }
                else {
                }

                return data;
            }
        }

        return null;
    }
}
