import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18nPlugin from "./plugins/i18nPlugin"
import veeValidatePlugin from "./plugins/veeValidatePlugin"
import bootstrapPlugin from "./plugins/bootstrapPlugin"
import alertPlugin from "./plugins/alertPlugin"
import zoomPlugin from "./plugins/zoomPlugin"

Vue.config.productionTip = false

const i18n = i18nPlugin.register()

veeValidatePlugin.register()

bootstrapPlugin.register()

alertPlugin.register()

zoomPlugin.register()

const app = new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')

export default app
