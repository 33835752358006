import app from "../main"
import config from "../config"

export default {
    confirm: async function (message, options = {}) {
        const mOptions = {
            title: config.appName,
            showCancelButton: true,
            confirmButtonText: app.$t('Accept'),
            cancelButtonText: app.$t('Cancel')
        };

        Object.keys(options).forEach((key) => mOptions[key] = options[key])
        mOptions['html'] = app.$t(message);

        const result = await app.$swal(mOptions);

        return result.isConfirmed;
    },
    alert: async function (message, type = null, options = {}) {
        const mOptions = {
            title: config.appName,
            icon: type || 'error',
            confirmButtonText: app.$t('Accept')
        };

        Object.keys(options).forEach((key) => mOptions[key] = options[key])
        mOptions['html'] = app.$t(message);
        await app.$swal(mOptions);
    }
}
