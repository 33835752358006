import Vue from 'vue'
import Vuex from 'vuex'
import config from "../config"
import app from "../main"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        locale: null,
        meetingData: null
    },
    getters: {
        locale(state) {
            if (state.locale)
                return state.locale

            try {
                const locale = window.localStorage.getItem("locale")
            } catch (e) {
                console.log(e)
            }

            return config.locale.default
        },
        meetingData: state => state.meetingData
    },
    mutations: {
        SET_LOCALE: (state, payload) => {
            app.$i18n.locale = payload
            state.locale = payload
            try {
                window.localStorage.setItem("locale", payload)
            } catch (e) {
                console.log(e)
            }
        },
        SET_MEETING_DATA: (state, payload) => state.meetingData = payload
    },
    actions: {
        setLocale: ({commit}, payload) => commit("SET_LOCALE", payload),
        setMeetingData: ({commit}, payload) => commit("SET_MEETING_DATA", payload),
    }
})
