import httpHelper from "../helpers/httpHelper";

const meetingService = {
    async initJoin(meetingId) {
        return await httpHelper.get("/v2/Meeting/InitJoin", {
            onMeetingId: meetingId
        })
    },
    async join(data) {
        return await httpHelper.get("/v2/Meeting/Join", data)
    }
}

export default meetingService
