const config = {
    "apiUrl": "",
    "_apiUrl": "http://localhost:8888",
    "locale": {
        "default": "vi",
        "dictionary": {
            "vi": "vi-VN"
        }
    }
}

export default config
